<template>
  <div class="d-flex justify-center align-center text-h6 clickable" @click="dialog = true" v-if="verifications">
    <span><i class="ml-2 fad fa-badge-check success--text"></i>Verified By:</span>
    <div style="width:95px">
      <v-carousel
        :show-arrows="false"
        hide-delimiters
        height="40"
        max="95"
        class="elevation-0"
        continuous cycle
        interval="3000"
      >
        <v-carousel-item
          height="40px"
          v-for="(logo) in logos"
          :key="`${logo}`"
          :src="logo"
          contain
        ></v-carousel-item>
      </v-carousel>
    </div>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>Verification</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="color2 color2Text--text" fab small @click.stop="dialog=false">
            <v-icon>fs fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="verifications.length === 1" class="pa-3">
           <v-card>
             <v-card-text>
               <div><b>{{verifications[0].org.name}}</b></div>
               <v-divider ></v-divider>
               <div class="text-subtitle-1">
                 Verified: {{getMetrics(verifications[0].v[0].selected) | formatArray}}
               </div>
               <div class="mt-3 text-caption">Verified On: {{verifications[0].v[0].dtVerified | localDate}}</div>
               <div class="text-caption">Verified By: {{verifications[0].v[0].verifiedBy}}</div>
             </v-card-text>
           </v-card>
        </v-card-text>
        <v-slide-group
          v-else
          v-model="slide"
          show-arrows
          class="pa-3"
        >
          <v-slide-item
            v-for="v in verifications"
            :key="v.org.id"
          >
           <v-card width="250" min-height="250" class="ma-3" :color="`${v.org.theme.color1}`">
             <v-card-text :style="`color: ${v.org.theme.color1Text}`">
               <div><b>{{v.org.name}}</b></div>
               <v-divider :color="v.org.theme.color1Text"></v-divider>
               <div class="text-subtitle-1">
                 Verified: {{getMetrics(v.v[0].selected) | formatArray}}
               </div>
               <div class="mt-3 text-caption">Verified On: {{v.v[0].dtVerified | localDate}}</div>
               <div class="text-caption">Verified By: {{v.v[0].verifiedBy}}</div>
             </v-card-text>
           </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import uniqBy from 'lodash.uniqby'
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: ['player'],
  data () {
    return {
      dialog: false,
      slide: false,
      metrics: [
        { text: 'Height', value: 'height' },
        { text: 'Dominant Hand', value: 'dominantHand' },
        { text: 'Standing Reach', value: 'reach' },
        { text: 'Wingspan', value: 'wingspan' },
        { text: 'Block Jump Touch', value: 'blockTouch' },
        { text: 'Approach Jump Touch', value: 'approachTouch' },
        { text: 'Star Drill', value: 'starDrill' }
      ]
    }
  },
  computed: {
    ...mapGetters(['subdomain']),
    verifications () {
      if (this.player.verifications.length === 0) return null
      const v = this.player.verifications.map(m => {
        m.unix = moment(m.dtCreated).unix()
        return m
      })
      var orgs = uniqBy(v.map(m => m.onBehalfOf), 'id')
      return orgs.map(m => {
        return {
          org: m,
          v: v.filter(f => f.behalfOf === m.id).sort(firstBy('unix', -1))
        }
      })
    },
    orgs () {
      return this.player && this.player.verifications && this.player.verifications.length > 0 && uniqBy(this.player.verifications.map(m => m.onBehalfOf), 'id')
    },
    logos () {
      return this.orgs && this.orgs.map(m => m.theme.logoUrl)
    }
  },
  methods: {
    getMetrics (vals) {
      return vals && vals.length && this.metrics.filter(f => vals.includes(f.value)).map(m => m.text)
    },
    open () {
      this.dialog = true
    }
  }
}
</script>
